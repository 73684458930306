@import '../../../App.scss';

#create {
  &-a {
    .text-fields {
      display: grid;
      grid-template-areas:
        'name'
        'symbol'
        'decimals'
        'total';
      grid-gap: 1.2rem;
      margin-bottom: 1.2rem;

      .name-input {
        grid-area: name;
        width: 93%;
        padding: 0.5rem 1rem;
      }

      .symbol-input {
        grid-area: symbol;
        width: 93%;
        padding: 0.5rem 1rem;
      }

      .decimal-input {
        grid-area: decimals;
        width: 93%;
        padding: 0.5rem 1rem;
      }

      .total-input {
        grid-area: total;
        width: 93%;
        padding: 0.5rem 1rem;
      }

      .warning {
        color: red;
      }

      .required:after {
        content: " *";
        color: red;
      }
    }

    button[type='submit'] {
      width: 50%;
    }
  }
}

//Smart Phones
@media(max-width: 500px) {
  #create {
    &-a {
      .text-fields {
        display: grid;
        // grid-template-columns: 1fr;
        grid-template-areas:
          'name'
          'symbol'
          'decimals'
          'total';
      }
    }
  }
}
