@import '../../../App.scss';

#create {
  &-e {
    .loader {
      position: absolute;
      left: 50%;
      top: 57%;
      z-index: 1;
      margin: -76px 0 0 -76px;
      border: 16px solid #f3f3f3; /* Light grey */
      border-top: 16px solid $main-color;
      border-bottom: 16px solid $main-color;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      animation: spin 2s linear infinite;
    }
    .notice {
      margin-bottom: 80px;
    }

    .control-buttons {
      margin-top: 80px;
    }

    button {
      width: 40%;
      margin: 0.5rem 1rem;
    }
  }
  
}

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


//Smart Phones
// @media(max-width: 500px) {
//   #create {
//     &-a {
//       .text-fields {
//         display: grid;
//         // grid-template-columns: 1fr;
//         grid-template-areas:
//           'name'
//           'symbol'
//           'decimals'
//           'total';
//       }
//     }
//   }
// }
