@import '../../App.scss';

//header
#header {
  &-iner {
    // background: $bg-image no-repeat 30% 5% / cover;
    background-color: #030216;
    height: 5rem;
    color: $green-color;
    border-bottom: 3px solid $main-color;
  }
  .current {
    border-bottom: 3px solid $main-color;
  }
  .active {
    border-bottom: 3px solid $main-color;
  }
}
//Smart Phones
@media(max-width: 500px) {
  #header {
    &-home {
      height: 10rem;
      border-bottom: 3px solid $main-color;
      background-position: 30% 5%;
      .header-content {
        display: none;
      }
    }
    &-iner {
      height: 10rem;
      background-position: 30% 5%;
    }
  }
}
