@import '../../../App.scss';

#create {
  &-c {
    .text-fields {
      display: grid;
      grid-template-areas:
        'address1'
        'address2'
        'address3'
        'address4'
        'address5'
        'address6'
        'address7'
        'address8'
        'address9'
        'address10';
      grid-gap: 1.2rem;
      margin-bottom: 1.2rem;

      .address1-input {
        grid-area: address1;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address2-input {
        grid-area: address2;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address3-input {
        grid-area: address3;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address4-input {
        grid-area: address4;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address5-input {
        grid-area: address5;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address6-input {
        grid-area: address6;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address7-input {
        grid-area: address7;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address8-input {
        grid-area: address8;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address9-input {
        grid-area: address9;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .address10-input {
        grid-area: address10;
        width: 93%;
        padding: 0.5rem 1rem;
      }

      .warning {
        color: red;
      }

      .required:after {
        content: " *";
        color: red;
      }
    }
    button {
      width: 40%;
      margin: 0.5rem 1rem;
    }
  }
}

//Smart Phones
@media(max-width: 500px) {
  #create {
    &-a {
      .text-fields {
        display: grid;
        // grid-template-columns: 1fr;
        grid-template-areas:
          'name'
          'symbol'
          'decimals'
          'total';
      }
    }
  }
}
