@import '../../../App.scss';
//Home sections
#home {
  &-a {
    .specials {
      margin-top: 3rem;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(4, 1fr);
      .fas {
        color: $main-color;
        padding-bottom: 0.4rem;
      }
    }
    .phone-only {
      display: none;
    }
    .btn-main {
      margin-top: 15px;
    }
  }
}
//Smart Phones
@media(max-width: 500px) {
  #home-a .specials
  .items {
    grid-template-columns: 1fr;
  }
  #home {
    &-a {
      .specials div {
        border-bottom: 1px $light-color solid;
        padding-bottom: 1rem;
        &:last-child {
          border: none;
          padding-bottom: 0;
        }
      }
      .phone-only {
        display: block;
      }
      .no-coding {
        font-weight: bold;
      }
      .selling-points-phone {
        margin: 20px;
      }
    }
  }
}
