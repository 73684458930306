@import '../../App.scss';

//header
#header {
  &-home {
    // background: $bg-image no-repeat center 1% / cover;
    background-color: #030216;
    height: 100vh;
    color: $main-color;
    border-bottom: 3px solid $main-color;
  }
}
//Tablets and smaller
@media(max-width: 800px) {
  #header-home {
    height: 30rem;
    .header-content {
      padding-top: 5rem;
    }
  }
}
// Landscape
@media(max-height: 580px) {
  #header-home {
    .header-content {
      padding-top: 3rem;
      h1 {
        font-size: 2rem;
      }
    }
  }
}
@media(max-height: 330px) {
  #header-home {
    .header-content {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
