@import './../../App.scss';
//Header ::content
.header-content {
  text-align: center;
  padding-top: 5%;
  h1 {
    font-size: 4rem;
    line-height: 1.2;
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  span {
    font-size: 1.2rem;
  }
}
.fas {
  color: $main-color;
}
.new-lead {
  @extend .lead;
  border-bottom: 3px solid $white-color;
  color: $white-color;
}

.white-bottom-line {
  height: 2px;
  width: 3rem;
  background: $white-color;
  display: block;
  margin: 0 auto 1rem auto;
}

.type-effect {
  color: rgb(168, 168, 6);
  font-size: 30px;
  font-weight: bold;
}

.selling-points {
  margin-top: 7vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.each-selling-point {
  color: $white-color;
}

//medium screen
@media(max-width: 975px) {
 
  .main-h2 {
    display: none;
  }
  .white-bottom-line {
    display:none;
  }
}

//smaller screen
@media(max-width: 800px) {

  .selling-points {
    display: none;
  }
}