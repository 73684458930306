@import '../../../App.scss';

#create {
  &-d {
    .text-fields {
      display: grid;
      grid-template-areas:
        'transactionHash'
        'symbol'
        'decimals'
        'total';
      grid-gap: 1.2rem;
      margin-bottom: 1.2rem;

      .transaction-input {
        grid-area: transactionHash;
        width: 93%;
        padding: 0.5rem 1rem;
      }

      .warning {
        color: red;
      }

      .required:after {
        content: " *";
        color: red;
      }
    }

    // button {
    //   width: 40%;
    //   margin: 0.5rem 1rem;
    // }

    //directional buttons
    .direction {
      width: 40%;
      margin: 0.5rem 1rem;
    }

    //copy button
    .copy {
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .wallet {
      font-weight: bold;
      color: $main-color;
    }

    .bnb-amount {
      color: $main-color;
      font-weight: bold;
    }
  }
}

//Smart Phones
@media(max-width: 500px) {
  #create {
    &-d {
      .text-fields {
        display: grid;
        // grid-template-columns: 1fr;
        grid-template-areas:
          'name'
          'symbol'
          'decimals'
          'total';
      }
    }
  }
}
