@import '../../App.scss';

//Main Nav Bar
#main-nav {
  display:flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 6px;
  }

  ul {
    list-style: none;
    display: flex;
    a {
      text-transform: capitalize;
      color: $white-color;
      border-bottom: 3px solid transparent;
      padding-bottom: 0.5rem;
      transition: border-color 1s;
      &:hover {
        border-color: $medium-color;
      }
      &.current {
        border-color: $white-color;
      }
    }
  }
  li {
    padding: 1rem;
  }

}
//logo
// #logo {
//   font-family: 'Lobster', cursive;
//   text-align: center;
//   width: 100px;
//   height: 50px;
//   font-size: 2rem;
//   color: $white-color;
//   text-transform: uppercase;
//   border: 5px solid transparent;
//   border-radius: 50%;
//   padding: 2px;
//   transition: all 0.5s;
//   &:hover {
//     border-color: $light-color;
//   }
// }

//Smart Phones
@media(max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center;
    li {
      padding: 1rem;
    }
  }
}
