//Variables
$website-width: 1000px;
$main-color: #0b5801;
$light-color: #f4f4f4;
$dark-color: #333;
$medium-color: #ccc;
$orange-color: #ff3d00;
$black-color: #010101;
$white-color: #fff;
$green-color: #10753c;
$img-path: '';
$bg-image: url(/honey.png);

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #034638;
}

@function set-text-color($color) {
  @if(lightness($color) > 50) {
    @return $black-color;
  }
  @else {
    @return $white-color;
  }
}
